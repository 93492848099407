import React, { Children, ReactNode } from 'react';

interface PageProps {
  title: string;
  children: ReactNode;
}

const Page: React.FC<PageProps> = ({ title, children }) => {
    return (
        <div>
            <h2 className='text-5xl md:text-7xl my-10 w-fit py-2 text-black font-extrabold'>{title}</h2>
            {children}
        </div>
    );
}

export default Page;
