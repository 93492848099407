import React from 'react';
import Page from '../Page';
import Button from '../Button';

interface Product {
    name: string;
    description: string;
    price: number;
    paymentLink: string;
}

interface ProductsProps {
    products: Product[];
}

const Products: React.FC<ProductsProps> = ({ products}) => {
    return (
        <Page title='Products - Coming soon!'>

<div className='text-2xl flex flex-col gap-8'>
                <p>
                    Soon you will be able to buy any of the nails I have used in the photoshoots here
                </p>
                <p>
                    Until then, you can recieve custom nails by contacting me at 07391157155 or nailartbyashton@gmail.com
                </p>

            </div>


            {/* <div className="grid grid-cols-1 gap-4 md:gap-8">
                {products.map((product,index) => (
                    <div key={`product-${index}`} className="border-2 border-black rounded-md p-4">
                        <div className="flex flex-col md:flex-row">
                            <img
                                src="https://via.placeholder.com/150"
                                alt={`${product.name}`}
                                className="w-full md:w-1/2 aspect-square md:max-w-[250px] md:max-h-[250px]"
                            />
                            <div className="md:ml-4  flex flex-col  justify-space  my-10 md:mt-0  gap-2">
                                <div className="flex flex-col items-center md:items-start  gap-2">
                                    <h3 className="text-2xl">{product.name}</h3>
                                    <p className="text-base">{product.description}</p>
                                    <p className="text-xl">${product.price}</p>

                                    <a href={product.paymentLink} className='mt-4 flex flex-col items-center md:items-start' target="_blank" rel="noopener noreferrer">
                                        <Button>Buy Now</Button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div> */}
        </Page>

    );

};

export default Products;
