export default [
  // {
  //   label: "Home",
  //   href: "/"
  // },
  {
    label: "About",
    href: "/about"
  },
  {
    label: "Portfolio",
    href: "/portfolio"
  },
  {
    label: "Book",
    href: "/book"
  },
  {
    label: "Shop",
    href: "/shop",
  },
  {
    label: "Learn",
    href: "/workshops",
  },
];
