import React from 'react';
import Page from '../Page';

export default function About() {
    return (
        <Page title='About'>
            <div className='text-2xl flex flex-col gap-8'>
                <p>
                    After 10 years of working in Finance, specialising in Corporate Tax and becoming a qualified ICAEW Chartered Accountant in London, Ashton formalised her 20 years of Nail Art experience in 2020 to pursue her dreams of becoming a Nail Artist and Creative.
                </p>
                <img className='h-[600px]  rounded-md h-auto w-max' src='ashton.jpg' />
                <p>
                    Ashton’s Nail Art style is bright and bold with a focus on patterns, texture and combining colours to produce uplifting and adventurous Art. Her work has been featured at London Fashion Week, celebrity clients, beauty and product shoots, brand launch parties, TV production and brand activitations.                 </p>
                <p>
                    When not experimenting with new products and creating unique designs, Ashton specifically keeps clients in mind and can create bespoke looks which are both eye catching yet wearable and will always garner attention.                </p>
            </div>

        </Page>
    );
}
