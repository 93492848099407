import React from 'react';
import Page from '../Page';

export default function Workshops() {
    return (
        <Page title='Workshops - Coming Soon'>
            <div className='text-2xl flex flex-col gap-8'>
                <p>

                    Until then, you can reach out to discuss workshops by contacting me at 07391157155 or nailartbyashton@gmail.com
                </p>

            </div>
        </Page>

    );
}
