export default [{
    id: 'prod_1',
    name: 'Product 1',
    description: 'This is product 1',
    price: 20,
    paymentLink: 'https://buy.stripe.com/test_4gw3cH8VbdwR5I4288',
    images: [
      'https://via.placeholder.com/300x300',
      'https://via.placeholder.com/150x150',
      'https://via.placeholder.com/150x150',
    ],
  },
  {
    id: 'prod_2',
    name: 'Product 2',
    description: 'This is product 2',
    price: 30,
    paymentLink: 'https://buy.stripe.com/test_4gw3cH8VbdwR5I4288',
    images: [
      'https://via.placeholder.com/300x300',
      'https://via.placeholder.com/150x150',
      'https://via.placeholder.com/150x150',
    ],
  },
  {
    id: 'prod_2',
    name: 'Product 2',
    description: 'This is product 2',
    price: 30,
    paymentLink: 'https://buy.stripe.com/test_4gw3cH8VbdwR5I4288',
    images: [
      'https://via.placeholder.com/300x300',
      'https://via.placeholder.com/150x150',
      'https://via.placeholder.com/150x150',
    ],
  },  {
    id: 'prod_2',
    name: 'Product 2',
    description: 'This is product 2',
    price: 30,
    paymentLink: 'https://buy.stripe.com/test_4gw3cH8VbdwR5I4288',
    images: [
      'https://via.placeholder.com/300x300',
      'https://via.placeholder.com/150x150',
      'https://via.placeholder.com/150x150',
    ],
  },  {
    id: 'prod_2',
    name: 'Product 2',
    description: 'This is product 2',
    price: 30,
    paymentLink: 'https://buy.stripe.com/test_4gw3cH8VbdwR5I4288',
    images: [
      'https://via.placeholder.com/300x300',
      'https://via.placeholder.com/150x150',
      'https://via.placeholder.com/150x150',
    ],
  },  {
    id: 'prod_2',
    name: 'Product 2',
    description: 'This is product 2',
    price: 30,
    paymentLink: 'https://buy.stripe.com/test_4gw3cH8VbdwR5I4288',
    images: [
      'https://via.placeholder.com/300x300',
      'https://via.placeholder.com/150x150',
      'https://via.placeholder.com/150x150',
    ],
  },  {
    id: 'prod_2',
    name: 'Product 2',
    description: 'This is product 2',
    price: 30,
    paymentLink: 'https://buy.stripe.com/test_4gw3cH8VbdwR5I4288',
    images: [
      'https://via.placeholder.com/300x300',
      'https://via.placeholder.com/150x150',
      'https://via.placeholder.com/150x150',
    ],
  },  {
    id: 'prod_2',
    name: 'Product 2',
    description: 'This is product 2',
    price: 30,
    paymentLink: 'https://buy.stripe.com/test_4gw3cH8VbdwR5I4288',
    images: [
      'https://via.placeholder.com/300x300',
      'https://via.placeholder.com/150x150',
      'https://via.placeholder.com/150x150',
    ],
  }]