import { Image } from "react-grid-gallery";

export interface CustomImage extends Image {
  original: string;
}

export const images: Record<string, CustomImage[]> = {
"10 space x keira": [
{
            original: "10 space x keira/5-IMG_6341.JPG",
            width: 5100,
            height: 6375,
            src: "10 space x keira/thumbnail_5-IMG_6341.JPG",
        },
{
            original: "10 space x keira/4-IMG_6345.JPG",
            width: 5100,
            height: 6375,
            src: "10 space x keira/thumbnail_4-IMG_6345.JPG",
        },
{
            original: "10 space x keira/3-IMG_6344.JPG",
            width: 5100,
            height: 6375,
            src: "10 space x keira/thumbnail_3-IMG_6344.JPG",
        },
{
            original: "10 space x keira/2-IMG_6346.JPG",
            width: 5100,
            height: 6375,
            src: "10 space x keira/thumbnail_2-IMG_6346.JPG",
        },
{
            original: "10 space x keira/1-IMG_6340.JPG",
            width: 5100,
            height: 6375,
            src: "10 space x keira/thumbnail_1-IMG_6340.JPG",
        },
{
            original: "10 space x keira/0-IMG_6349.JPG",
            width: 5100,
            height: 6375,
            src: "10 space x keira/thumbnail_0-IMG_6349.JPG",
        },
],
"09 bianca x mob": [
{
            original: "09 bianca x mob/4-IMG_8447.jpg",
            width: 3255,
            height: 4883,
            src: "09 bianca x mob/thumbnail_4-IMG_8447.jpg",
        },
{
            original: "09 bianca x mob/3-IMG_8451.jpg",
            width: 4297,
            height: 6446,
            src: "09 bianca x mob/thumbnail_3-IMG_8451.jpg",
        },
{
            original: "09 bianca x mob/2-IMG_8448.jpg",
            width: 4344,
            height: 6516,
            src: "09 bianca x mob/thumbnail_2-IMG_8448.jpg",
        },
{
            original: "09 bianca x mob/1-IMG_8453.jpg",
            width: 4084,
            height: 6126,
            src: "09 bianca x mob/thumbnail_1-IMG_8453.jpg",
        },
],
"08 pink x Xae ": [
{
            original: "08 pink x Xae /5-IMG_8492.JPG",
            width: 2595,
            height: 3633,
            src: "08 pink x Xae /thumbnail_5-IMG_8492.JPG",
        },
{
            original: "08 pink x Xae /3-IMG_8488.JPG",
            width: 2785,
            height: 3899,
            src: "08 pink x Xae /thumbnail_3-IMG_8488.JPG",
        },
{
            original: "08 pink x Xae /3-IMG_8484.JPG",
            width: 2747,
            height: 3846,
            src: "08 pink x Xae /thumbnail_3-IMG_8484.JPG",
        },
{
            original: "08 pink x Xae /2-IMG_8474.JPG",
            width: 3177,
            height: 4448,
            src: "08 pink x Xae /thumbnail_2-IMG_8474.JPG",
        },
{
            original: "08 pink x Xae /1-IMG_8486.JPG",
            width: 2375,
            height: 3325,
            src: "08 pink x Xae /thumbnail_1-IMG_8486.JPG",
        },
],
"07 beauty x annie": [
{
            original: "07 beauty x annie/5-IMG_8459.JPG",
            width: 2240,
            height: 2899,
            src: "07 beauty x annie/thumbnail_5-IMG_8459.JPG",
        },
{
            original: "07 beauty x annie/4-IMG_8458.JPG",
            width: 2240,
            height: 2899,
            src: "07 beauty x annie/thumbnail_4-IMG_8458.JPG",
        },
{
            original: "07 beauty x annie/3-IMG_8464.JPG",
            width: 2240,
            height: 2899,
            src: "07 beauty x annie/thumbnail_3-IMG_8464.JPG",
        },
{
            original: "07 beauty x annie/2-IMG_8470.JPG",
            width: 2240,
            height: 3360,
            src: "07 beauty x annie/thumbnail_2-IMG_8470.JPG",
        },
{
            original: "07 beauty x annie/1-IMG_8472.JPG",
            width: 2240,
            height: 2899,
            src: "07 beauty x annie/thumbnail_1-IMG_8472.JPG",
        },
],
"06 beauty x ella": [
{
            original: "06 beauty x ella/IMG_5529.JPG",
            width: 2550,
            height: 3300,
            src: "06 beauty x ella/thumbnail_IMG_5529.JPG",
        },
{
            original: "06 beauty x ella/IMG_5527.JPG",
            width: 2550,
            height: 3300,
            src: "06 beauty x ella/thumbnail_IMG_5527.JPG",
        },
{
            original: "06 beauty x ella/IMG_5523.JPG",
            width: 2550,
            height: 3300,
            src: "06 beauty x ella/thumbnail_IMG_5523.JPG",
        },
{
            original: "06 beauty x ella/IMG_5522.JPG",
            width: 2550,
            height: 3300,
            src: "06 beauty x ella/thumbnail_IMG_5522.JPG",
        },
{
            original: "06 beauty x ella/IMG_5520.JPG",
            width: 2550,
            height: 3300,
            src: "06 beauty x ella/thumbnail_IMG_5520.JPG",
        },
],
"05 jewellry x samanta": [
{
            original: "05 jewellry x samanta/000096930009 EDIT SHADOW.jpg",
            width: 2048,
            height: 2565,
            src: "05 jewellry x samanta/thumbnail_000096930009 EDIT SHADOW.jpg",
        },
{
            original: "05 jewellry x samanta/000096930003 copy.jpg",
            width: 2048,
            height: 2565,
            src: "05 jewellry x samanta/thumbnail_000096930003 copy.jpg",
        },
{
            original: "05 jewellry x samanta/000096880003 copy.jpg",
            width: 2314,
            height: 2016,
            src: "05 jewellry x samanta/thumbnail_000096880003 copy.jpg",
        },
{
            original: "05 jewellry x samanta/000096850004 copy.jpg",
            width: 2048,
            height: 2565,
            src: "05 jewellry x samanta/thumbnail_000096850004 copy.jpg",
        },
{
            original: "05 jewellry x samanta/000096840010 crop. jpg.jpg",
            width: 1384,
            height: 1778,
            src: "05 jewellry x samanta/thumbnail_000096840010 crop. jpg.jpg",
        },
],
"04 colour-series-x-lily": [
{
            original: "04 colour-series-x-lily/B-8.jpg",
            width: 6720,
            height: 4480,
            src: "04 colour-series-x-lily/thumbnail_B-8.jpg",
        },
{
            original: "04 colour-series-x-lily/B-6.jpg",
            width: 4374,
            height: 5660,
            src: "04 colour-series-x-lily/thumbnail_B-6.jpg",
        },
{
            original: "04 colour-series-x-lily/B-16.jpg",
            width: 4387,
            height: 6040,
            src: "04 colour-series-x-lily/thumbnail_B-16.jpg",
        },
{
            original: "04 colour-series-x-lily/B-15.jpg",
            width: 4480,
            height: 6720,
            src: "04 colour-series-x-lily/thumbnail_B-15.jpg",
        },
{
            original: "04 colour-series-x-lily/B-0.jpg",
            width: 6720,
            height: 4480,
            src: "04 colour-series-x-lily/thumbnail_B-0.jpg",
        },
],
"03 barbie-food-shoot": [
{
            original: "03 barbie-food-shoot/Barbie_WFH 4-5.jpg",
            width: 2000,
            height: 2500,
            src: "03 barbie-food-shoot/thumbnail_Barbie_WFH 4-5.jpg",
        },
{
            original: "03 barbie-food-shoot/Barbie_Girls Night In Nails Close Up3.jpg",
            width: 4341,
            height: 4341,
            src: "03 barbie-food-shoot/thumbnail_Barbie_Girls Night In Nails Close Up3.jpg",
        },
{
            original: "03 barbie-food-shoot/Barbie_Girls Night In Nails Close Up2.jpg",
            width: 4341,
            height: 4341,
            src: "03 barbie-food-shoot/thumbnail_Barbie_Girls Night In Nails Close Up2.jpg",
        },
{
            original: "03 barbie-food-shoot/Barbie_Girls Night In 4-5.jpg",
            width: 2000,
            height: 2500,
            src: "03 barbie-food-shoot/thumbnail_Barbie_Girls Night In 4-5.jpg",
        },
],
"02 sea-creatues": [
{
            original: "02 sea-creatues/Sea_Creatures_Hair_Editorial_Photographer_Nadia_Correia6.jpg",
            width: 2000,
            height: 2500,
            src: "02 sea-creatues/thumbnail_Sea_Creatures_Hair_Editorial_Photographer_Nadia_Correia6.jpg",
        },
{
            original: "02 sea-creatues/Sea_Creatures_Hair_Editorial_Photographer_Nadia_Correia3.jpg",
            width: 2000,
            height: 2500,
            src: "02 sea-creatues/thumbnail_Sea_Creatures_Hair_Editorial_Photographer_Nadia_Correia3.jpg",
        },
{
            original: "02 sea-creatues/Sea_Creatures_Hair_Editorial_Photographer_Nadia_Correia2.jpg",
            width: 2500,
            height: 2000,
            src: "02 sea-creatues/thumbnail_Sea_Creatures_Hair_Editorial_Photographer_Nadia_Correia2.jpg",
        },
{
            original: "02 sea-creatues/Sea_Creatures_Hair_Editorial_Photographer_Nadia_Correia18.jpg",
            width: 2000,
            height: 2500,
            src: "02 sea-creatues/thumbnail_Sea_Creatures_Hair_Editorial_Photographer_Nadia_Correia18.jpg",
        },
{
            original: "02 sea-creatues/Sea_Creatures_Hair_Editorial_Photographer_Nadia_Correia15.jpg",
            width: 2000,
            height: 2500,
            src: "02 sea-creatues/thumbnail_Sea_Creatures_Hair_Editorial_Photographer_Nadia_Correia15.jpg",
        },
{
            original: "02 sea-creatues/Sea_Creatures_Hair_Editorial_Photographer_Nadia_Correia13.jpg",
            width: 2000,
            height: 2500,
            src: "02 sea-creatues/thumbnail_Sea_Creatures_Hair_Editorial_Photographer_Nadia_Correia13.jpg",
        },
],
"01 beauty-x-brit": [
{
            original: "01 beauty-x-brit/006c.jpg",
            width: 2240,
            height: 2899,
            src: "01 beauty-x-brit/thumbnail_006c.jpg",
        },
{
            original: "01 beauty-x-brit/005c.jpg",
            width: 2240,
            height: 2899,
            src: "01 beauty-x-brit/thumbnail_005c.jpg",
        },
{
            original: "01 beauty-x-brit/003c.jpg",
            width: 2240,
            height: 2899,
            src: "01 beauty-x-brit/thumbnail_003c.jpg",
        },
{
            original: "01 beauty-x-brit/001c.jpg",
            width: 2240,
            height: 2899,
            src: "01 beauty-x-brit/thumbnail_001c.jpg",
        },
],
"001 - LFW AW23 x Mona": [
{
            original: "001 - LFW AW23 x Mona/dc884ace-1f43-477d-a8de-d61142b3da12.jpg",
            width: 1193,
            height: 1600,
            src: "001 - LFW AW23 x Mona/thumbnail_dc884ace-1f43-477d-a8de-d61142b3da12.jpg",
        },
],
"00 02:22 - LFW AW22 x Mona": [
{
            original: "00 02:22 - LFW AW22 x Mona/_DSF4648.jpg",
            width: 4160,
            height: 6240,
            src: "00 02:22 - LFW AW22 x Mona/thumbnail__DSF4648.jpg",
        },
{
            original: "00 02:22 - LFW AW22 x Mona/_DSF4177.jpg",
            width: 4160,
            height: 6240,
            src: "00 02:22 - LFW AW22 x Mona/thumbnail__DSF4177.jpg",
        },
{
            original: "00 02:22 - LFW AW22 x Mona/_DSF3878 (1).jpg",
            width: 6240,
            height: 4160,
            src: "00 02:22 - LFW AW22 x Mona/thumbnail__DSF3878 (1).jpg",
        },
{
            original: "00 02:22 - LFW AW22 x Mona/_DSF3840 (1).jpg",
            width: 4160,
            height: 6240,
            src: "00 02:22 - LFW AW22 x Mona/thumbnail__DSF3840 (1).jpg",
        },
{
            original: "00 02:22 - LFW AW22 x Mona/_DSF3660 (1).jpg",
            width: 4160,
            height: 6240,
            src: "00 02:22 - LFW AW22 x Mona/thumbnail__DSF3660 (1).jpg",
        },
],
};
