import { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { images as imagesDictionary, CustomImage } from "./imageConfig";
import { SocialIcon } from 'react-social-icons';

import NavBar from "./Navigation";
import About from "./Pages/About";
import Portfolio from "./Pages/Portfolio";
import Pricing from "./Pages/Pricing";
import Corporate from "./Pages/Corporate";
import Groups from "./Pages/Groups";
import Workshops from "./Pages/Workshops";
import Editorial from "./Pages/Editorial";
import Products from "./Pages/Products";

import sampleProducts from './Settings/products'
import sampleItems from './Settings/pricing'
import navItems from './Settings/navigation'
import HomePage from "./Pages/HomePage";

// Flatten the image dictionary to get a single array of images
const allImages: CustomImage[] = Object.values(imagesDictionary).flat();

const slides = allImages.map(({ original, width, height }) => ({
  src: original,
  width,
  height,
}));


export default function App() {
  const [index, setIndex] = useState(-1);

  const handleClick = (index: number, item: CustomImage) => setIndex(index);

  return (
    <Router>
      <div>
        <div className="container  p-4 pb-[200px] mx-auto max-w-5xl">




          <NavBar items={navItems} />





          <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/corporate" element={<Corporate />} />
            <Route path="/editorial" element={<Editorial />} />
            <Route path="/groups" element={<Groups />} />
            <Route path="/workshops" element={<Workshops />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/shop" element={<Products products={sampleProducts} />} />
            <Route path="/book" element={<Pricing items={sampleItems} />} />
            <Route path="/" element={<Portfolio />} />

            {/* <Route path="*" element={<Page404 />} /> */}
          </Routes>




        </div>
      </div>
    </Router>
  );
}
