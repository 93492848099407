export default {
  hands: [
    {
      name: "Luxury Manicure",
      duration: "45min",
      price: 40,
      
    },
    {
      name: "Elim Manicure - No Polish",
      duration: "1h 15min",
      price: 62,
      
    },
    {
      name: "PureBuild Manicure",
      duration: "2h",
      price: 74,
      
    },
    {
      name: "French Gel Polish Manicure",
      duration: "1h 15min",
      price: 62,
      
    },
    {
      name: "Luxury Gel Polish Manicure",
      duration: "1h",
      price: 50,
      
    }
  ],
  feet: [
    {
      name: "Elim Pedicure - no polish",
      duration: "1h",
      price: 60,
      
    },
    {
      name: "Elim Pedicure",
      duration: "1h 15min",
      price: 65,
      
    },
    {
      name: "Gel Polish Pedicure",
      duration: "1h",
      price: 50,
      
    }
  ],
  nailArt: [
    { name: "Simple Art", duration: null, price: 5 },
    { name: "Complex Art", duration: null, price: 15 }
  ],
  extras: [
    { name: "Nail Repair", duration: null, price: 10 },
    { name: "Hand Massage", duration: null, price: 15 }
  ]
};