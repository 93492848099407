import React from 'react';
import Page from '../Page';

export default function Corporate() {
    return (
        <Page title='Corporate'>
            <div className='text-2xl flex flex-col gap-8'>
                <p>
                    Pop Ups for team events, work parties or office paper days - all are possible and can include Manicures, Pedicures and creation of custom Nail Art menus which can be tailored to your event to be made express, regular polish or gel polish.
                </p>
                <p>
                    To receive a consultation and quote you can complete the Enquiry Form on the Contact page or contact me directly on 07391157155
                </p>

            </div>

        </Page>
    );
}
