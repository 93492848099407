import React from 'react';
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import { images as imagesDictionary, CustomImage } from "../imageConfig";
import { SocialIcon } from 'react-social-icons';


// Flatten the image dictionary to get a single array of images
const allImages: CustomImage[] = Object.values(imagesDictionary).flat();

const slides = allImages.map(({ original, width, height }) => ({
    src: original,
    width,
    height,
}));

export default function Portfolio() {
    const [index, setIndex] = React.useState(-1);

    const handleClick = (index: number, item: CustomImage) => setIndex(index);

    return (
        <div>

            {/* <h4 className="text-2xl ">Ashton is an experienced Nail Artist with a history at London Fashion Week events, media production, commissioned brand work, editorial shoots, and bespoke services for celebrities.</h4> */}

            <div className="flex gap-2 py-4 items-center">
                <a className="mr-4 font-bold" href="mailto:nailartbyashton@gmail.com"><span>nailartbyashton@gmail.com</span></a>
                <SocialIcon url="https://www.instagram.com/nailartbyashton" bgColor="transparent" fgColor="black" />
                {/* <SocialIcon url="https://www.linkedin.com/feed/" bgColor="transparent" fgColor="black" /> */}
            </div>
            <Gallery
                images={allImages}
                onClick={handleClick}
                enableImageSelection={false}
            />
            <Lightbox
                slides={slides}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
            />
        </div>
    );
}
