import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserIcon, Bars3Icon, XCircleIcon, ChevronDownIcon } from "@heroicons/react/24/solid"
import logo from './images/naba-logo.svg'

interface NavItem {
  label: string;
  href: string;
  subLinks?: NavItem[];  // Sub-links
}

interface NavBarProps {
  items: NavItem[];
}

const NavBar: React.FC<NavBarProps> = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState<string | null>(null); // State to manage which submenu is open

  useEffect(() => {
    if (isOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'unset';
  }, [isOpen]);

  const handleSubMenuToggle = (label: string) => {
    if (subMenuOpen === label) {
      setSubMenuOpen(null);
    } else {
      setSubMenuOpen(label);
    }
  }

  return (
    <>
      <div className='flex items-center justify-between'>
        <Link to={'/'}>
        <img className="h-14 md:h-20 my-8" src={logo} />
        </Link>
        <div
          className='md:hidden z-20 cursor-pointer z-50'
          onClick={() => setIsOpen(!isOpen)}
        >
          <Bars3Icon className=" h-8 w-8" />
        </div>
      </div>

      <nav className='my-4 font-bold relative'>
        {/* Mobile Nav */}
        {isOpen && (
            <div className='fixed top-0 left-0 w-full h-full bg-white opacity-95 z-50 flex flex-col justify-start  items-center md:hidden overflow-y-auto'>
            <XCircleIcon onClick={() => setIsOpen(false)} className=" text-black absolute top-10 h-16 w-16" />
            <ul className='flex flex-col pt-10 mt-32  pb-40 items-center gap-10 text-4xl overflow-y-auto w-full'>
              {items.map((item,index) => (
                <li className=' text-black' key={`${item.label}-${index}`}>
                  {item.subLinks ? (
                    <span onClick={() => handleSubMenuToggle(item.label)}>
                      <div className='flex items-center   '>
                        {item.label} <ChevronDownIcon className={`left-2 w-10 h-10 relative top-[3px] ${subMenuOpen && 'rotate-180'}`} />
                      </div>
                    </span>
                  ) : (
                    <Link to={item.href} onClick={() => setIsOpen(false)}>
                      {item.label}
                    </Link>
                  )}
                  {item.subLinks && subMenuOpen === item.label && (
                    <ul className="flex flex-col items-center gap-5 text-2xl mt-6">
                      {item.subLinks.map((subItem,index) => (
                        <li className='hover:underline text-black' key={`${subItem.label}-${index}`}>
                          <Link to={subItem.href} onClick={() => setIsOpen(false)}>
                            {subItem.label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Desktop Nav */}
        <ul className='hidden md:flex gap-3 relative'>
          {items.map(item => (
            <li className='group hover:underline text-lg relative' key={item.label}>
              <div
                onMouseEnter={() => setSubMenuOpen(item.label)}
                onMouseLeave={() => setSubMenuOpen(null)}
              >
                {item.subLinks ? (
                  <span className='flex items-center cursor-pointer'>
                    {item.label}
                     <ChevronDownIcon className='ml-2 w-5 h-5' />
                  </span>
                ) :
                  <Link to={item.href}>
                    <span className='flex items-center cursor-pointer'>
                      {item.label}
                    </span>
                  </Link>
                }
                {subMenuOpen === item.label && item.subLinks && (
                  <ul className="absolute hv-absolute top-full left-0 p-2 -mt-[2px] mr-1 bg-white border border-gray-300 rounded shadow-md">
                    {item.subLinks.map((subItem,index) => (
                      <Link key={`${subItem.label}-${index}`} to={subItem.href}>
                        <li className='hover:bg-gray-100 p-2' key={subItem.label}>
                          {subItem.label}
                        </li>
                      </Link>
                    ))}
                  </ul>
                )}
              </div>
            </li>
          ))}
        </ul>

      </nav>
    </>
  );
}

export default NavBar;