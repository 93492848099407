import React from 'react';
import Page from '../Page';
import Button from '../Button';
import { Link } from 'react-router-dom';

interface PriceItem {
    name: string;
    price: number;
}

interface PricingProps {
    items: {
        hands?: PriceItem[];
        feet?: PriceItem[];
        nailArt?: PriceItem[];
        extras?: PriceItem[];
    };
}

const Pricing: React.FC<PricingProps> = ({ items }) => {
    return (

        <Page title='Booking - Coming soon'>

            <div className='text-2xl flex flex-col gap-8'>
                <p>
                    Until then, you can book my services by contacting me at 07391157155 or nailartbyashton@gmail.com
                </p>

            </div>

        </Page>
        // <Page title='Pricing'>
        //     <div className='text-2xl max-w-[500px] flex flex-col gap-8'>



        //         <Link to={'https://www.fresha.com/a/nail-art-by-ashton-london-south-east-london-gn3hxb52/booking'}>
        //             <Button>Book Now</Button>
        //         </Link>
        //         {Object.entries(items).map(([category, entries]) => (
        //             <div key={category}>
        //                 <h3 className='text-4xl font-bold  my-5'>{category.charAt(0).toUpperCase() + category.slice(1)}</h3>
        //                 <div className='flex flex-col gap-2'>
        //                     {entries.map((item, index) => (
        //                         <div key={index} className='flex justify-between gap-4'>
        //                             <div className="name">{item.name}</div>
        //                             <div className="price font-bold">£{item.price}</div>
        //                         </div>
        //                     ))}
        //                 </div>
        //             </div>
        //         ))}
        //         <Link to={'https://www.fresha.com/a/nail-art-by-ashton-london-south-east-london-gn3hxb52/booking'}>
        //         <Button>Book Now</Button>
        //     </Link>
        // </div>
        // </Page >

    );
}

export default Pricing;


