import React from 'react';
import Page from '../Page';

export default function Groups() {
    return (
        <Page title='Groups'>
            <div className='text-2xl flex flex-col gap-8'>
                <p>
                    Group bookings are available as ‘Pamper Packages’ for special occasions such as birthdays, bridal parties and baby showers or for preparing for a great afternoon and evening our with friends!
                </p>
                <p>
                    Nail services include Manicures, Pedicures and creation of custom Nail Art menus which can be tailored to your event to be made express, regular polish or gel polish.
                </p>
                <p>
                    To receive a consultation and quote you can complete the Enquiry Form on the Contact page or contact me directly on 07391157155
                </p>
            </div>
        </Page>

    );
}
